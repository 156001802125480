import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, Autoplay } from "swiper";

const imgs = [
  require("../assets/chapa-expandida.png"),
  require("../assets/chapa-expandida-01.png"),
  require("../assets/chapa-expandida-02.png"),
  require("../assets/chapa-expandida-03.png"),
];

export default () => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        width={462}
        spaceBetween={50}
        className="swiper-service"
        loop={true}
        autoplay={true}
        navigation={true}
      >
        <SwiperSlide key="slide1">
          <img src={imgs[1]} alt="Chapa expandida" />
        </SwiperSlide>
        <SwiperSlide key="slide2">
          <img src={imgs[2]} alt="Chapa expandida" />
        </SwiperSlide>
        <SwiperSlide key="slide3">
          <img src={imgs[3]} alt="Chapa expandida" />
        </SwiperSlide>
      </Swiper>
      <div className="desc">
        <div className="text">
          <h1>Chapa expandida</h1>
          <p>
            Se destacando por sua rentabilidade, as chapas expandida surgem como
            a melhor opção para quem procura pela relação custo benefício.
          </p>
        </div>
        <div className="photo">
          <img src={imgs[0]} alt="Chapa expandida" />
        </div>
      </div>
    </>
  );
};
