import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, Autoplay } from "swiper";

export default () => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        width={462}
        spaceBetween={50}
        className="swiper-service"
        loop={true}
        autoplay={true}
        navigation={true}
      >
        <SwiperSlide key="slide1">
          <img src="assets/tube-laser-01.png" alt="Corte de tubo a laser" />
        </SwiperSlide>
        <SwiperSlide key="slide2">
          <img src="assets/tube-laser-02.png" alt="Corte de tubo a laser" />
        </SwiperSlide>
        <SwiperSlide key="slide3">
          <img src="assets/tube-laser-03.png" alt="Corte de tubo a laser" />
        </SwiperSlide>
      </Swiper>
      <div className="desc">
        <div className="text">
          <h1>Corte a laser em tubos</h1>
          <p>Corte em tubos metalicos de diferças bitolas.</p>
          <p>
            Corte feito em maquinas de alta precisão com excelente acabamento.
          </p>
        </div>
        <div className="photo">
          <img src="assets/laser-tube.png" alt="Corte de tubo a laser" />
        </div>
      </div>
    </>
  );
};
