import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, Autoplay } from "swiper";

export default () => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        width={462}
        spaceBetween={50}
        className="swiper-service"
        loop={true}
        autoplay={true}
        navigation={true}
      >
        <SwiperSlide key="slide1">
          <img src="assets/laser-01.png" alt="" />
        </SwiperSlide>
        <SwiperSlide key="slide2">
          <img src="assets/laser-02.png" alt="" />
        </SwiperSlide>
        <SwiperSlide key="slide3">
          <img src="assets/laser-03.png" alt="" />
        </SwiperSlide>
      </Swiper>
      <div className="desc">
        <div className="text">
          <h1>Corte a laser em chapas</h1>
          <p>Corte a laser em chpas metalicas de diversas espessuras.</p>
          <p>Corte com precisão e rapidez na medida do seu projeto.</p>
        </div>
        <div className="photo">
          <img src="assets/laser.png" alt="" />
        </div>
      </div>
    </>
  );
};
