import React, { useEffect, useState } from "react";
import Arames from "./components/Arames";
import Tubos from "./components/Tubos";
import Laser from "./components/Laser";
import TubeLaser from "./components/TubeLaser";
import ChapaExpandida from "./components/ChapaExpandida";
import Contato from "./components/Contato";
import Dutos from "./components/Dutos";

const defaultClsService = { laser: "", tubeLaser: "", tubes: "", arames: "" };
const defautlClsProduct = { chapa: "", dutos: "" };

const startClass = { ...defaultClsService, arames: "laser" };

function App() {
  const [pageService, setPageService] = useState("laser");
  const [pageProduct, setPageProduct] = useState("chapa");

  const [linkClass, setLinkClass] = useState(startClass);
  const [clsProduct, setClsProduct] = useState({
    ...defautlClsProduct,
    chapa: "active",
  });

  useEffect(() => {
    setLinkClass({ ...defaultClsService, [pageService]: "active" });
  }, [pageService]);

  useEffect(() => {
    setClsProduct({ ...defautlClsProduct, [pageProduct]: "active" });
  }, [pageProduct]);

  function productPage() {
    switch (pageProduct) {
      case "chapa":
        return <ChapaExpandida />;
      case "dutos":
        return <Dutos />;
      default:
        return <div>ERRO</div>;
    }
  }

  const servicePage = () => {
    switch (pageService) {
      case "laser":
        return <Laser />;
      case "tubes":
        return <Tubos />;
      case "tubeLaser":
        return <TubeLaser />;
      case "arames":
        return <Arames />;
      default:
        return <div>ERRO</div>;
    }
  };

  return (
    <>
      <section id="services">
        <div className="app">
          <div className="menu">
            <ul>
              <li>
                <a
                  href="#lasercut"
                  onClick={() => setPageService("laser")}
                  className={linkClass.laser}
                >
                  Corte a laser em chapas
                </a>
              </li>
              <li>
                <a
                  href="#lasercut"
                  onClick={() => setPageService("tubeLaser")}
                  className={linkClass.tubeLaser}
                >
                  Corte a laser em tubos
                </a>
              </li>
              <li>
                <a
                  href="#lasercut"
                  onClick={() => setPageService("arames")}
                  className={linkClass.arames}
                >
                  Dobra de arame
                </a>
              </li>
              <li>
                <a
                  href="#lasercut"
                  onClick={() => setPageService("tubes")}
                  className={linkClass.tubes}
                >
                  Dobra de tubos
                </a>
              </li>
            </ul>
          </div>
          <div className="content">{servicePage()}</div>
        </div>
      </section>
      <section id="products">
        <div className="app">
          <div className="menu">
            <ul>
              <li>
                <a
                  href="#chapaExpandida"
                  onClick={() => setPageProduct("chapa")}
                  className={clsProduct.chapa}
                >
                  Chapa Expandida
                </a>
                <a
                  href="#dutos"
                  onClick={() => setPageProduct("dutos")}
                  className={clsProduct.dutos}
                >
                  Dutos soldados
                </a>
              </li>
            </ul>
          </div>
          <div className="content">{productPage()}</div>
        </div>
      </section>
      <section id="contact">
        <Contato />
      </section>
    </>
  );
}

export default App;
