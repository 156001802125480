import React, { useState } from "react";

import http from "axios";

export default () => {
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [message, setMessage] = useState("");

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setMessage("Enviando sua mensagem, aguarde...");
    const fData = new FormData();
    fData.append("name", data.name);
    fData.append("email", data.email);
    fData.append("phone", data.phone);
    fData.append("message", data.message);
    console.log(e.target);
    http.post("./send.php", fData).then((e) => {
      setMessage(
        "Mensagem enviada com sucesso, obrigado pelo contato, muito em breve retornaremos com a resposta sobre sua duvida."
      );
    });
  };
  return (
    <div className="form">
      <div className="text">
        <h1>Precisa de ajuda?</h1>
        <p>
          Entre em contato com um de nossos representantes diretamente através
          telefone ou e-mail.
        </p>
        <p>
          <span className="material-icons">phone</span>
          <a href="tel:+551136013400">(11) 3601-3400</a>
        </p>
        <p>
          <span className="material-icons">email</span>
          <a href="mailto:percapi@percapi.com.br">percapi@percapi.com.br</a>.
        </p>
        <p>
          <span className="material-icons">map</span>
          <a
            href="https://goo.gl/maps/tYumCoCo1qKXBfXf8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Rua Américo Vespúcio, 106 - Vila Menck, Osasco - SP
          </a>
        </p>
      </div>
      <form action="send.php" method="post" onSubmit={onSubmit}>
        <div className="line">
          <label htmlFor="name">Nome:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={data.name}
            onChange={onChange}
            required
          />
        </div>
        <div className="line">
          <label htmlFor="email">E-mail:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={data.email}
            onChange={onChange}
            required
          />
        </div>
        <div className="line">
          <label htmlFor="phone">Telefone:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={data.phone}
            onChange={onChange}
            required
          />
        </div>
        <div className="line">
          <label htmlFor="message">Mensagem:</label>
          <textarea
            name="message"
            id="message"
            value={data.message}
            onChange={onChange}
            required
          ></textarea>
        </div>
        <div className="line message">{message}</div>
        <div className="button">
          <button type="submit">Enviar</button>
        </div>
      </form>
    </div>
  );
};
