import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, Autoplay } from "swiper";

export default () => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        width={462}
        spaceBetween={50}
        className="swiper-service"
        loop={true}
        autoplay={true}
        navigation={true}
      >
        <SwiperSlide key="slide1">
          <img src="assets/arame-01.png" alt="" />
        </SwiperSlide>
        <SwiperSlide key="slide2">
          <img src="assets/arame-02.png" alt="" />
        </SwiperSlide>
        <SwiperSlide key="slide3">
          <img src="assets/arame-03.png" alt="" />
        </SwiperSlide>
      </Swiper>
      <div className="desc">
        <div className="text">
          <h1>Dobra de arames</h1>
          <p>Dobra de arame de diversas espessuras.</p>
          <p>Em diversas formas com a precisão exigida.</p>
        </div>
        <div className="photo">
          <img src="assets/arames.png" alt="" />
        </div>
      </div>
    </>
  );
};
