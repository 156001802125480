import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, Autoplay } from "swiper";

export default () => {
  return (
    <>
      <div className="photo">
        <img src="assets/tubos.png" alt="" />
      </div>
      <div className="desc">
        <div className="text">
          <h1>Dobra de tubos</h1>
          <p>Dobra de tubos e canos de diversas bitola.</p>
          <p>
            Dobramos tubos cilindricos, quadrados, inox, galvanizado, na forma
            que seu projeto exigir.
          </p>
        </div>
        <div className="photo-desc">
          <img src="assets/tubo.png" alt="" />
        </div>
      </div>
    </>
  );
};
